import React, { useEffect, useState } from 'react';
import Layout from 'src/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { Loader } from 'amphitheatre';
import { StaticQuery, graphql, Link, PageProps } from 'gatsby';
import axios from 'axios';

import * as Hero from 'src/page-styles/hero.styled';
import * as Base from 'src/page-styles/base.styled';

interface Props {
  location: Location;
  site: {
    siteMetadata: {
      rawURL: string;
    };
  };
}

function AccountConfirmation({ location, site }: Props) {
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>();
  const { rawURL } = site.siteMetadata;
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    axios
      .get(`/api/account/email/confirm/${token}`)
      .then((response) => {
        if (response.status === 200) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
      })
      .catch(() => setIsSuccess(false));
  }, []);

  let displayElement = <Loader message="Loading..." />;

  if (isSuccess) {
    displayElement = (
      <div>
        <Base.Heading style={{ fontWeight: 'bold' }}>
          Thank you, your Saber Account is confirmed.
        </Base.Heading>
        <Base.Paragraph>
          To subscribe to Saber's paid services, log in with the button above or
          by clicking{' '}
          <a
            href={`https://account.${rawURL}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            HERE
          </a>
          .
        </Base.Paragraph>
        <Base.Paragraph>
          To access TAROT immediately for free, click{' '}
          <a
            href={`https://tarot.${rawURL}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            HERE
          </a>{' '}
          to get started via your web browser.
        </Base.Paragraph>
        <Base.Paragraph>
          Subscribed? Head on over to the <Link to="/downloads">Downloads</Link>{' '}
          page to get the latest version of PIGI.
        </Base.Paragraph>
      </div>
    );
  } else if (isSuccess === false) {
    displayElement = (
      <div>
        <Base.Heading style={{ fontWeight: 'bold' }}>
          Registration Failed.
        </Base.Heading>
        <Base.Paragraph>
          To reattempt registration, please try again on the signup page{' '}
          <a
            href={`https://account.${rawURL}/register`}
            rel="noopener noreferrer"
            target="_blank"
          >
            HERE
          </a>
          .
        </Base.Paragraph>
      </div>
    );
  }

  return (
    <Layout>
      <Hero.Wrapper>
        <Hero.Cover />
        <StaticImage
          className="hero-image"
          src="../images/tarot/Background.jpg"
          alt="hero"
        />
        <Hero.Text style={{ textAlign: 'center' }}>{displayElement}</Hero.Text>
      </Hero.Wrapper>
    </Layout>
  );
}

export default function ({ location }: PageProps): JSX.Element {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              rawURL
            }
          }
        }
      `}
      render={({ site }) => (
        <AccountConfirmation site={site} location={location} />
      )}
    />
  );
}
